_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "Por favor, introduza o valor correspondente para {0}",
	"crm.label.field":"Campo",//no i18n
"crm.label.value":"Valor",//no i18n
	"sentiment.criteria.wrongcriteria":"O valor dos crit\u00e9rios n\u00e3o pode ser superior a {0}",//no i18n
"crm.mb.field.common.splc":"N\u00e3o s\u00e3o permitidos carateres especiais. Insira um valor v\u00e1lido.",//no i18n
	"crm.label.field.plural":"campos",//no i18n
"crm.label.in.minutes":"{0} (dentro de minutos)",//no i18n
	"crm.security.roles.list":"Lista de fun\u00e7\u00f5es",//no i18n
"crm.security.roles.lookup.info":"Selecione uma fun\u00e7\u00e3o da lista.",//no i18n
"crm.territory.addterritory":"Adicionar Territ\u00f3rio",//no i18n
"crm.title.edit.territory":"Editar Territ\u00f3rio",//no i18n
"crm.territory.title.assign.territories":"Atribuir Territ\u00f3rios",//no i18n
"crm.label.context.help":"Ajuda",//no i18n
	"crm.label.from":"De",//no i18n
"crm.label.to":"Para",//no i18n
	"workflow.option.webhookFailure.fromDate":"Desde",//no i18n
"workflow.option.webhookFailure.toDate":"At\u00e9",//no i18n
"crm.custom.field.less.than.equalto":"{0} deve ser inferior ou igual a {1}.",//no i18n
	"crm.template.listview.search.no.results":"N\u00e3o foram encontrados resultados",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"A data \u201cDe\u201d n\u00e3o pode ser posterior \u00e0 data \u201cAt\u00e9\u201d.",//no i18n
	"crm.label.tag.new":"Nova etiqueta",//No I18n
	"crm.label.enter.tag":"Introduzir etiquetas",//No I18n
	"crux.comboBox.max.limit":"N\u00e3o \u00e9 poss\u00edvel selecionar mais de {0} {1}.",//NO I18n
	"Administrator":"Administrador",//No I18n
	"Standard":"Padr\u00e3o",//No I18n
	"crm.button.add":"Adicionar",//NO I18n
	"crm.label.users":"Utilizadores", //NO I18n
  "crm.workflow.alert.roles":"Fun\u00e7\u00f5es", //NO I18n
  "crm.security.groups":"Grupos", //NO I18n
	"crm.label.available" : "Dispon\u00edvel", //NO I18n
	"crm.label.assign.manually" : "Atribuir", //NO I18n
	"crm.globalsearch.option.all": "Todos", //NO I18n
	"webform.status.Active":"Ativo", //NO I18n
	"Inactive":"Inactivo", //NO I18n
  "Confirmed":"Confirmado", //NO I18n
  "crm.user.component.unconfirmed":"Por confirmar",//no i18n
  "DeletedUser":"Eliminado", //NO I18n
  "crm.feed.group.admin":"Admin", //NO I18n
  "crm.ln.lable.current":"Actual", //NO I18n
	"crm.label.selected": "Seleccionada",//NO I18n
	"crm.auditlog.user": "Utilizador", //NO I18n
	"cob.role": "Fun\u00e7\u00e3o", //NO I18n
	"zoho.email": "E-mail", //NO I18n
	"Profile": "Perfil", //NO I18n
	"crm.security.group.users.empty": "N\u00e3o foram encontrados utilizadores.", //NO I18n
	"crm.label.picklist.none": "Nenhum", //NO I18n
	"crm.usrpop.non.selected" : "Utilizadores selecionados",//NO I18n
	"crm.zti.label.user": "Nome de utilizador", //NO I18n
	"crm.label.notSelected" : "N\u00e3o selecionado",//NO I18n
	"AM" : "AM",//NO I18n
	"Call" : "Chamada",//NO I18n
	"crm.phoneNo.Link.Title" : "Efetuar uma chamada utilizando o Skype",//NO I18n
	"crm.button.cancel" : "Cancelar",//NO I18n
	"crm.button.save" : "Guardar",//NO I18n
	"crm.no.data.found" : "N\u00e3o foram encontrados dados.",//NO I18n
	"crm.label.no.options.found" : "Nenhuma op\u00e7\u00e3o encontrada.",//No I18n
	"crm.globalsearch.search.title" : "Pesquisar",//No I18n
	"None" : "Nenhum",//No I18n
	"crm.label.criteria.pattern" : "Padr\u00e3o de crit\u00e9rios",//No I18n
	"crm.label.edit.criteria.pattern" : "Editar padr\u00e3o",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Os par\u00eanteses retos do padr\u00e3o n\u00e3o correspondem.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Par\u00eanteses inv\u00e1lidos em volta do(s) operador(es) da condi\u00e7\u00e3o.",//No I18n
	"crm.criteria.number.notmatch.check" : "Verifique o padr\u00e3o em {0}.",//No I18n
	"criteria.error.alert.other.params" : "Conte\u00fado inv\u00e1lido neste padr\u00e3o.", //No I18n
	"crm.label.search.for.users": "Procurar utilizadores", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "O Padr\u00e3o de crit\u00e9rios n\u00e3o corresponde \u00e0s condi\u00e7\u00f5es que escolheu.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "O Padr\u00e3o de crit\u00e9rios n\u00e3o corresponde \u00e0s condi\u00e7\u00f5es que escolheu.", //No I18n
	"and" : "e", //No I18n
	"or" : "ou", //No I18n
	"crm.label.or" : "OU", //No I18n
	"crm.label.and" : "E", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Introduza uma etiqueta de campo v\u00e1lida na linha {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "Especifique uma condi\u00e7\u00e3o v\u00e1lida para {0}.", //No I18n
	"crm.field.valid.check" : "Introduza um {0} v\u00e1lido.", //No I18n
	"crm.custom.field.less.than.to" : "O intervalo <i>De</i> n\u00e3o pode ser superior ao intervalo <i>Para</i>.", //No I18n
	"crm.alert.label.savepattern" : "Guardar o padr\u00e3o antes de alterar os crit\u00e9rios.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "N\u00e3o pode adicionar crit\u00e9rios adicionais.",//No I18n
	"is" : "\u00e9",//No I18n
	"isn\'t" : "n\u00e3o \u00e9",//No I18n
	"contains" : "cont\u00e9m",//No I18n
	"doesn\'t contain" : "n\u00e3o cont\u00e9m",//No I18n
	"starts with" : "inicia com",//No I18n
	"ends with" : "termina com",//No I18n
	"is empty" : "est\u00e1 vazia",//No I18n
	"is not empty" : "n\u00e3o est\u00e1 vazio",//No I18n
	"is before" : "\u00e9 antes",//No I18n
	"is after" : "\u00e9 depois",//No I18n
	"between" : "entre",//No I18n
	"not between" : "n\u00e3o entre",//No I18n
	"Today" : "Hoje",//No I18n
	"Tommorow" : "Amanh\u00e3",//No I18n
	"Tommorow Onwards" : "A come\u00e7ar amanh\u00e3",//No I18n
	"Yesterday" : "Ontem",//No I18n
	"Till Yesterday" : "Amanh\u00e3",//No I18n
	"Last Month" : "M\u00eas passado",//No I18n
	"Current Month" : "M\u00eas actual", //No I18n
	"Next Month" : "M\u00eas seguinte", //No I18n
	"Last Week" : "Semana passada", //No I18n
	"Current Week" : "Semana actual", //No I18n
	"Next Week" : "Semana seguinte", //No I18n
	"Age in Days" : "Dura\u00e7\u00e3o em dias", //No I18n
	"Due in Days" : "Vence em dias", //No I18n
	"Scheduled" : "Programados", //No I18n
	"Attended Dialled" : "Marcada atendida", //No I18n
	"Unattended Dialled" : "Marcada n\u00e3o atendida", //No I18n
	"Overdue" : "Atrasadas", //No I18n
	"Cancelled" : "Cancelado(a)", //No I18n
	"Received" : "Recebida", //No I18n
	"Missed" : "Perdida", //No I18n
	"crm.alert.character.not.allowed" : "{0} n\u00e3o \u00e9 permitido", //No I18n
	"crm.condition.in.last" : "nos \u00faltimos", //No I18n
	"crm.zinvoice.dueIn" : "vence em", //No I18n
	"on" : "Em",//No I18n
	"before" : "antes de",//No I18n
	"crm.label.general.small.after" : "depois",//No I18n
	"crm.thisweek" : "Esta semana",//No I18n
	"crm.label.this.month" : "Este m\u00eas",//No I18n
	"crm.thisyear" : "Este ano",//No I18n
	"crm.source.user.and.system" : "Utilizador e sistema",//No I18n
	"crm.source.user.or.system" : "Utilizador ou sistema",//No I18n
	"crm.label.system2" : "Sistema",//No I18n
	"crm.source.user.only" : "Apenas pelo utilizador",//No I18n
	"crm.source.system.only" : "Apenas pelo sistema",//No I18n
	"crm.condition.till.today" : "At\u00e9 hoje",//No I18n
	"game.month.left" : "1 m\u00eas",//No I18n
	"game.months.left" : "{0} meses",//No I18n
	"crm.condition.last.30.days" : "nos \u00faltimos 30 dias",//No I18n
	"crm.condition.last.60.days" : "nos \u00faltimos 60 dias",//No I18n
	"crm.condition.last.90.days" : "nos \u00faltimos 90 dias",//No I18n
	"crm.label.filter.typehere" : "Escrever aqui", //No I18N
	"crm.filter.is.not" : "n\u00e3o \u00e9", //No I18n
	"crm.condition.until.now" : "At\u00e9 ao momento",//No I18n
	"crm.filter.email.isblocked" : "est\u00e1 bloqueada",//No I18n
	"crm.filter.email.isnotblocked" : "n\u00e3o est\u00e1 bloqueada",//No I18n
	"crm.label.no.results.match" : "Pesquisa sem resultados",//No I18n
	"crm.label.select.user" : "Clicar para selecionar utilizadores.", //No I18n
	"current.logged.in.user": "Utilizador com sess\u00e3o iniciada", //NO I18n
	"current.logged.in.user.definition": "O utilizador que inicia a a\u00e7\u00e3o de registo.", //NO i18n
	"crm.security.group": "Grupo", //NO I18n
	"crm.security.role": "Fun\u00e7\u00e3o", //NO I18n
	"Date" : "Data",//No I18n
	"crm.field.valid.decimal.check2" : "As casas decimais para o campo <i>{0}</i> devem ser inferiores ou iguais a {1}.",//No I18n
	"crm.field.empty.check" : "{0} n\u00e3o pode estar em branco.",//No I18n
	"crm.label.add.note": "Adicionar nota", //NO I18n
	"crm.label.simply.by": "por", //NO I18n
	"crm.general.addnote": "Adicionar uma nota", //NO I18n
	"crm.general.addtitle": "Adicionar um t\u00edtulo", //NO I18n
	"crm.label.attach.file": "Anexar ficheiro", //NO I18N
	"crm.button.mass.delete": "Eliminar", //NO I18N
	"crm.warning.delete.record": "Tem a certeza de que pretende mover \"{0}\" para a reciclagem?", //NO I18N
	"crm.label.yes": "Sim", //NO I18N
	"crm.note.view.previous": "Ver notas anteriores", //NO I18N
  "of": "de", //NO I18N
	"crm.label.notes": "Notas", //NO I18N
	"crm.note.recent.first": "Recente primeiro", //NO I18N
	"crm.note.recent.last": "Recente \u00faltimo", //NO I18N
	"crm.territory.label.only": "{0} apenas", //no i18n
	"crm.select" : "Seleccionar",//No I18n
	"crm.button.apply.filter" : "Aplicar filtro",//No I18n
	"crm.alert.maximum.text.values.contains" : "N\u00e3o pode inserir mais do que {0} valores para este campo.",//No I18n
	"PM" : "PM",//No I18n
	"crm.mb.newversion.msg4" : "OK, percebi!",//No I18n
	"Jan" : "Jan",//No I18n
	"Feb" : "Fev",//No I18n
	"Mar" : "Mar",//No I18n
	"Apr" : "Abr",//No I18n
	"Jun" : "Jun",//No I18n
	"Jul" : "Jul",//No I18n
	"Aug" : "Ago",//No I18n
	"Sep" : "Set",//No I18n
	"Oct" : "Out",//No I18n
	"Nov" : "Nov",//No I18n
	"Dec" : "Dez",//No I18n
	"crm.label.More" :"Mais", //no i18n

	"crm.label.unmapped.stages":"UnAccounted", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Mostrar mais",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Mostrar menos",//No I18n

	 	 //filter related keys-start
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.tooltip.convert":"PROBABILIDADE",//no i18n
"crm.lead.prediction.tooltip.score":"PONTUA\u00c7\u00c3O",//no i18n
"Planned":"Planeada",//no i18n
"Invited":"Convidado",//no i18n
"Sent":"Enviado",//no i18n
"Received":"Recebida",//no i18n
"Opened":"Aberto",//no i18n
"Responded":"Respondido",//no i18n
"Bounced":"Devolvido",//no i18n
"Opted\ Out":"Exclu\u00eddo",//no i18n
"crm.filter.label.with.open":"Com {0} aberto",//no i18n
"crm.filter.label.without.open":"Sem abrir {0}",//no i18n
"crm.filter.label.without.any":"Sem quaisquer {0}",//no i18n
"crm.filter.label.with.module":"Com {0}",//no i18n
"crm.filter.label.activity.due":"Vence a {0}",//no i18n
"crm.filter.label.activity.done":"{0} conclu\u00eddo",//no i18n
"Notes":"Notas",//no i18n
"crm.filter.label.notes.added":"Notas adicionadas",//no i18n
"crm.label.filter.email.status":"Estado do \u00faltimo e-mail",//no i18n
"crm.label.filter.email.clicked":"clicados",//no i18n
"crm.label.filter.email.responded":"respondido", //no i18n
"crm.label.filter.email.info":"Filtre os registos com base no \u00faltimo estado dos seus e-mails enviados/recebidos.",//no i18n
"crm.filter.label.sent":"enviado",//no i18n
"crm.filter.label.not.sent":"n\u00e3o enviado",//no i18n
"crm.filter.label.opened":"aberto",//no i18n
"crm.filter.label.not.opened":"n\u00e3o aberto",//no i18n
"crm.filter.label.received":"recebido",//no i18n
"crm.filter.label.not.received":"n\u00e3o recebido",//no i18n
"crm.filter.label.bounced":"devolvido",//no i18n
"crm.filter.label.opened.not.replied":"aberto e n\u00e3o respondido", //no i18n
"crm.filter.label.any":"Qualquer um dos anteriores",//no i18n
"crm.zia.config.potential.amount":"Montante de {0}",//no i18n
"Quote\ Stage":"Per\u00edodo {0}",//no i18n
"crm.module.owner":"Propriet\u00e1rio do {0}",//no i18n
"Potential\ Closing\ Date":"{0} Data Encerrada",//no i18n
"crm.lead.prediction.likely.convert":"Probabilidade de convers\u00e3o",//no i18n
"crm.lead.prediction.convert.high":"Alta",//no i18n
"crm.lead.prediction.convert.medium":"M\u00e9dia",//no i18n
"crm.lead.prediction.convert.low":"Baixa",//no i18n
"crm.predictions.feature.label":"Previs\u00e3o",//no i18n
"crm.intelligence.prediction.likelywin":"Probabilidade de ganhar",//no i18n
"crm.intelligence.prediction.likelylose":"Probabilidade de perder",//no i18n
"crm.intelligence.prediction.halfchance":"50% de hip\u00f3teses",//no i18n
"crm.intelligence.prediction.score":"Pontua\u00e7\u00e3o prevista",//no i18n
"crm.lead.prediction.recent.score":"Pontua\u00e7\u00e3o de previs\u00e3o recente",//no i18n
"crm.intelligence.prediction.lastconv":"\u00daltimas 3 conversa\u00e7\u00f5es",//no i18n
"crm.intelligence.prediction.recordsfocus":"Registos para foco",//no i18n
"crm.intelligence.prediction.slowmoving":"A evoluir lentamente",//no i18n
"crm.intelligence.prediction.trend.down":"Tend\u00eancia recentemente descendente",//no i18n
"crm.label.touched.records":"Registos alterados",//no i18n
"crm.label.untouched.records":"Registos inalterados",//no i18n
"crm.label.record.action":"A\u00e7\u00e3o de registo",//no i18n
"workflow.rule.view.label.Modified":"Modificada:",//no i18n
"crm.label.not.modified":"N\u00e3o modificado",//no i18n
"crm.label.related.records.action":"A\u00e7\u00e3o de registos relacionados",//no i18n
"Done":"Conclu\u00eddo",//no i18n
"crm.label.not.done":"N\u00e3o conclu\u00eddo",//no i18n
"sentiment.model":"Sentimento do e-mail",//no i18n
"sentiment.criteria.count":"Contagem",//no i18n
"sentiment.criteria.percentage":"Percentagem",//no i18n
"sentiment.criteria.lastmail":"Para o \u00faltimo e-mail",//no i18n
"Chats":"Chats",//no i18n
"Attended":"Frequ\u00eancia",//no i18n
"crm.lead.prediction.popup.text":"Se Probabilidade de convers\u00e3o for {0}, a pontua\u00e7\u00e3o de previs\u00e3o deve ser entre {1}.",//no i18n
"crm.lead.prediction.popup.final":"Altere o filtro em conformidade e tente novamente.",//no i18n
"crm.custom.field.less.than.to1":"O intervalo De n\u00e3o pode ser superior ao intervalo Para.",//no i18n
"Last\ Activity\ Date":"Data da \u00faltima atividade",//no i18n
"crm.label.vendor.name":"Nome do {0}",//no i18n
"hours":"horas",//no i18n
"days":"dias",//no i18n
"weeks":"semanas",//no i18n
"months":"meses",//no i18n
"years":"anos",//no i18n
"crm.label.general.small.after":"depois",//no i18n
"Last\ Week":"Semana passada",//no i18n
"Last\ Month":"M\u00eas passado",//no i18n
"crm.module.name":"Nome {0}",//no i18n
"Campaign":"Campanha",//no i18n
"Tasks":"Tarefas",//no i18n
"Calls":"Chamadas",//no i18n
"Events":"Eventos",//no i18n
"sentiment.criteria.wrongcriteria":"O valor dos crit\u00e9rios n\u00e3o pode ser superior a {0}",//no i18n
"crm.chosen.minimum.input.text":"Introduza {0} ou mais carateres",//no i18n
"crm.intelligence.prediction.trendup":"Tend\u00eancia ascendente",//no i18n
"crm.intelligence.prediction.trenddown":"Tend\u00eancia descendente",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"Conclu\u00edda" ,//no i18n
"crm.label.success":"Sucesso" ,//no i18n
"crm.label.Failure":"Falha" ,//no i18n
"Both":"Ambos" ,//no i18n
"crm.condition.cannot.empty":"A condi\u00e7\u00e3o n\u00e3o pode estar vazia.",//no i18n
"crm.condition.last.30.days":"nos \u00faltimos 30 dias",//no i18n
"crm.condition.last.60.days":"nos \u00faltimos 60 dias",//no i18n
"crm.condition.last.90.days":"nos \u00faltimos 90 dias",//no i18n
"crm.sentiment.Positive":"Positivo",//no i18n
"crm.sentiment.Negative":"Negativo",//no i18n
"sentiment.positiveandnegative":"Positivo e negativo",//no i18n
"sentiment.positiveornegative":"Positivo ou negativo",//no i18n
"sentiment.positiveonly":"Apenas positivo",//no i18n
"sentiment.negativeonly":"Apenas negativo",//no i18n
"crm.sentiment.Neutral":"Neutro",//no i18n
"crm.filters.select.campaign.type":"Selecionar tipo {0}",//no i18n
"crm.filters.select.campaign.status":"Selecionar estado {0}",//no i18n
"campaign.Member" : "Membro",//no i18n
	"Service":"Servi\u00e7o",//no i18n
"Activities":"Actividades",//no i18n
"crm.livedesk.pot.nextdays":"Pr\u00f3ximos {0} dias",//no i18n
"Today\ +\ Overdue":"Hoje + Atrasado",//no i18n
"crm.source.user.and.system":"Utilizador e sistema",//no i18n
"crm.source.user.or.system":"Utilizador ou sistema",//no i18n
"User":"Utilizador",//no i18n
"crm.source.user.only":"Apenas pelo utilizador",//no i18n
"crm.source.system.only":"Apenas pelo sistema",//no i18n
"Scheduled":"Programados",//no i18n
"Attended\ Dialled":"Marcada atendida",//no i18n
"Unattended\ Dialled":"Marcada n\u00e3o atendida",//no i18n
"Cancelled":"Cancelado(a)",//no i18n
"crm.filter.email.isblocked":"est\u00e1 bloqueada",//no i18n
"crm.filter.email.isnotblocked":"n\u00e3o est\u00e1 bloqueada",//no i18n
"condition.till.now":"At\u00e9 ao momento",//no i18n
"crm.recurring.no.months":"{0} meses",//no i18n
"crm.lead.prediction.tooltip":"Probabilidade de convers\u00e3o - intervalo de pontua\u00e7\u00e3o",//no i18n
"crm.website.activity":"Atividade no website",//no i18n
"crm.label.By":"Por",//no i18n
"crm.chosen.searching.text":"A pesquisar...",//no i18n
"crm.label.memberstatus.is":"e o estado do membro \u00e9",//no i18n
"crm.events.duration":"Dura\u00e7\u00e3o",//no i18n
"crm.title.clear.name":"Apagar",//no i18n
"crm.label.status.is":"e o estado \u00e9",//no i18n
"zia.last3.help":"As conversas incluem chamadas, tarefas, {0}, e-mail recebido, notas, visitas, coment\u00e1rios de redes sociais, pedidos de t\u00e9cnico de suporte.",//no i18n
"crm.label.tag.related.to":"relacionado com",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Um novo {0} ser\u00e1 criado.",//No I18n
	"crm.krp.no.records.found" : "N\u00e3o foram encontrados {0}.",//No I18n
	"crm.module.new" : "Novo(a) {0}",//No I18n
	"crm.label.view" : "Ver",//No I18n
	"crm.nsocial.customers" : "Clientes",//No I18n
	"crm.nsocial.open.potential" : "{0} aberto",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Outros",//No i18n
	"crm.field.length.check" : "O valor {0} excede o comprimento m\u00e1ximo.", //No I18n
	"crm.lower.now": "agora",//no i18n
	"crm.time.min.ago": "H\u00e1 {0} min",//no i18n
	"crm.time.mins.ago":"H\u00e1 {0} min",//no i18n
	"crm.time.hr.ago": "H\u00e1 {0} h",//no i18n
	"crm.time.hrs.ago": "H\u00e1 {0} horas", //no i18n
	"AllUsers": "Todos os Utilizadores", //no i18n
	"crm.label.search":"Pesquisar",//no i18n
	"crm.api.filterby":"Filtrar por",//no i18n
	"crm.customview.nofields.found":"--Nenhum campo correspondente--",//no i18n
	"crm.setup.system.ziarecommendation":"Recomenda\u00e7\u00e3o",//no i18n
	"crm.filter.label.all.products":"Todos os {0}",//no i18n
	"crm.filter.label.select.products":"{0} selecionado",//no i18n
	"crm.reviewprocess.smart.filter":"Status do registro processo de revis\u00e3o",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Escolha um valor.",//no i18n
	//"crm.segmentation.segment.score":"Pontua\u00e7\u00e3o do segmento",//no i18n
	"crm.filter.label.in":"baseado em",//no i18n
	"crm.filter.label.and.purchase.in":"e propenso a comprar em",//no i18n
	"crm.filter.label.last.purchased":"e recentemente adquiriu",//no i18n
	"crm.filter.label.a.day":"um dia",//no i18n
	"crm.filter.label.a.week":"uma semana",//no i18n
	"crm.filter.label.a.month":"um m\u00eas",//no i18n
	"crm.cal.custom":"Personalizado",//no i18n
	"crm.mb.field.common.empt":"O valor n\u00e3o pode ficar em branco.",//no i18n
	"crm.chosen.error.loading.text":"Ops, n\u00e3o foi poss\u00edvel carregar seus resultados",//no i18n
	"crm.filter.label.firstbuy":"Primeira vez",//no i18n
	"crm.filter.label.cwbab":"Dependente",//no i18n
	"crm.filter.label.fbt":"Agrupamento",//no i18n
	"crm.filter.label.rebuy":"Repetir",//no i18n
	"crm.filter.label.nextbuy":"Sequ\u00eancia",//no i18n
	"crm.mxnlookup.select" : "Atribuir {0}",//No I18n
	"crm.lookup.chooserecord":"Escolher {0}",//no i18n
	"crm.record.selected":"Selecionado {0}",//no i18n
	"crm.module.empty.message" : "N\u00e3o foi encontrado(a) nenhum(a) {0}",//No I18n
	"crm.mxnlookup.selected" : "{0} atribu\u00eddo",//No I18n
	"crm.label.creator.noPermission" : "Permiss\u00e3o negada", //No I18n
	"crm.security.error" : "N\u00e3o tem permiss\u00f5es suficientes para realizar esta opera\u00e7\u00e3o. Contactar o seu administrador.", //No I18n
	//"crm.segmentation.segment.score" : "\u05e0\u05d9\u05e7\u05d5\u05d3 \u05de\u05d3\u05d5\u05e8" //No I18n
	"crm.segmentation.segment.score" : "Classifica\u00e7\u00e3o do segmento", //No I18n
	"crm.segmentation.recency" : "Mais recente", //No I18n
	"crm.segmentation.frequency" : "Frequ\u00eancia", //No I18n
	"crm.segmentation.monetary" : "Moeda", //No I18n
	"crm.smartfilter.related.module.msg" : "N\u00e3o pode selecionar mais de tr\u00eas m\u00f3dulos relacionados.", //No I18n
	"crm.smartfilter.related.module.msg1" : "(Ex.:e-mail, Atividades, Notas)", //No I18n
	"crm.smartfilter.related.module.msg2" : "A dura\u00e7\u00e3o n\u00e3o pode estar vazia", //No I18n
	"crm.label.timeZone": "Fuso hor\u00e1rio", //NO I18n
	"crm.label.insufficient.privileges": "Privil\u00e9gios insuficientes para executar esta opera\u00e7\u00e3o. Contacte o administrador.", //NO I18n
	"crm.filter.header.secton.system": "Filtros definidos pelo sistema", //NO I18N
	"crm.filter.header.secton.fields": "Filtrar por Campos", //NO I18N
	"crm.createfield.calcinfo.new" : "Este campo atua como uma calculadora para qualquer express\u00e3o introduzida.</br> <b>Por exemplo, 20+20</b> apresenta automaticamente o resultado de <b>40</b>",//No i18n
	"crm.lable.read.only" : "Campo s\u00f3 de leitura",//No i18n
	"crm.column.sort.asc" : "Asc",//No I18n
	"crm.column.sort.desc" : "Desc",//No i18n
	"crm.column.unsort" : "Anular ordena\u00e7\u00e3o",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Partilhar com o cliente", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "Apenas {0} caracteres s\u00e3o permitidos para {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Partilhado com o cliente", //NO I18N
	"crm.button.ok" : "OK",  //NO I18N
	"crm.role.already.selected" : "Esta fun\u00e7\u00e3o j\u00e1 est\u00e1 selecionada", //no i18n
	"crm.user.deleted": "O UTILIZADOR FOI ELIMINADO",  //NO I18N
	"crm.account.closed": "ESTA CONTA FOI FECHADA",  //NO I18N
	"crm.start.chat": "Iniciar o chat",  //NO I18N
	"crm.start.call": "Iniciar uma chamada",  //NO I18N
	"crm.recipient.invalid.email" : "Foram encontrados e-mails inv\u00e1lidos.", //NO I18N
	"crm.recipient.add.recipient" : "Adicionar recipiente adicional", //NO I18N
	"crm.start.video.call": "Iniciar uma chamada de v\u00eddeo",  //NO I18N //ignorei18n_start

 	"crm.label.scoring.rules":"Regras de pontua\u00e7\u00e3o",
	"Score":"Pontua\u00e7\u00e3o",
	"Positive Score":"Pontua\u00e7\u00e3o positiva",
	"Negative Score":"Pontua\u00e7\u00e3o negativa",
	"Touch Point Score":"Pontua\u00e7\u00e3o de pontos de toque",
	"Positive Touch Point Score":"Pontua\u00e7\u00e3o de pontos de toque positiva",
	"Negative Touch Point Score":"Pontua\u00e7\u00e3o de pontos de toque negativa",
	"crm.label.type.minutes": "Escreva aqui em minutos", //NO I18N

	"is\ OPEN":"est\u00e1 ABERTO",//no i18n
	"is\ WON":"est\u00e1 GANHO",//no i18n
	"is\ LOST":"est\u00e1 PERDIDO",//no i18n
	"crm.potential.all.open":"Todas as fases abertas",//no i18n
	"crm.potential.all.won":"Todas as fases ganhas fechadas",//no i18n

	"crm.potential.all.lost":"Todas as fases perdidas fechadas",//no i18n
	"crm.campaign.member.status" : "Estado do membro",//no i18n
	"crm.dashboard.select.type" : "Selecionar {0}",//no i18n
	"crm.campaign.service.status":"Estado do servi\u00e7o",//no i18n

	"crm.label.addColumn":"Adicionar coluna",//no i18n
	"crm.button.clear.filter":"Fechar filtro",//no i18n
	"crm.button.show.filter":"Mostrar filtro",//no i18n
	"crm.las.error.user.maxlimit":"S\u00f3 pode selecionar, no m\u00e1ximo, 20 utilizadores.",//no i18n
	"crm.las.error.picklist.maxlimit":"S\u00f3 pode selecionar, no m\u00e1ximo, 20 op\u00e7\u00f5es.",//no i18n

	"crm.fileuploader.message.responseerror": "Falha no carregamento", //NO I18N
	"crm.storage.create.error":"N\u00e3o \u00e9 poss\u00edvel criar nenhum registo novo uma vez que atingiu o limite m\u00e1ximo de armazenamento de dados.",//no i18n
	"crm.storage.create.error.client":"N\u00e3o \u00e9 poss\u00edvel criar nenhum registo novo porque o seu administrados atingiu o respetivo limite m\u00e1ximo de armazenamento. Contacte {0} para resolver este problema.",//no i18n
	"crm.storage.avail.info":"({0} de {1} restantes)",//no i18n
	"crm.storage.error.key.manage":"Gerir o seu armazenamento de dados",//no i18n
	"Records":"Registos",//no i18n
	"crm.workflow.alert.additional.recipients" : "Destinat\u00e1rios adicionais", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Utilizar v\u00edrgulas para separar endere\u00e7os de e-mail adicionais.", //NO I18N
	"crm.related.contact.account" : "{0} relacionado com {1}",//No I18n
	"crm.allcontact.show" : "Todos {0}",//No I18n
	"crm.button.mass.show" : "Mostrar",//No I18n
	"crm.msg.custom.view.not.replied" : "Mensagens n\u00e3o respondidas", //NO I18N
	"crm.msg.custom.view.replied" : "Mensagem respondidas",//NO I18N
	"crm.workflow.select.recipients" : "Destinat\u00e1rios", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Selecione, pelo menos, um perfil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Lamentamos, mas n\u00e3o \u00e9 poss\u00edvel remover um perfil predefinido.",//NO I18N
	"crm.inv.label.add.emails" : "Adicionar e-mails" ,//NO I18N
	"crm.prediction.analytics.filter.year":"\u00daltimo ano",//no i18n
	"Previous\ FY":"Ano fiscal anterior",//no i18n
	"Current\ FY":"Ano fiscal actual",//no i18n
	"Next\ FY":"Pr\u00f3ximo ano fiscal",//no i18n
	"Current\ FQ":"Trimestre fiscal actual",//no i18n
	"Next\ FQ":"Pr\u00f3ximo trimestre fiscal",//no i18n
	"Previous\ FQ":"Trimestre fiscal anterior",//no i18n
	"crm.inv.label.add.emails" : "Adicionar e-mails", //NO I18N
	"crm.picklist.sample.text":"Texto de amostra",//no i18n
	"crm.more.colors":"Mais cores",//no i18n
	"crm.button.back.alone":"Voltar",//no i18n
	"crm.field.label.email":"E-mail",//no i18n
	"crm.zia.nba.feature.label":"Melhor a\u00e7\u00e3o seguinte",//no i18n
	"Meeting":"Reuni\u00e3o",//no i18n
	"Tomorrow":"Amanh\u00e3",//no i18n
	"crm.gdpr.notavailable.field":"N\u00e3o dispon\u00edvel",//no i18n
	"crm.setup.system.ziasimilarity":"Recomenda\u00e7\u00e3o de semelhan\u00e7a",//no i18n
	"crm.gdpr.notavailable.field":"N\u00e3o dispon\u00edvel",//no i18n
	"crm.filter.label.all.products":"Todos os {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due" ,//NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crux.custom.field.greater.than.equalto":"{0} deve ser superior ou igual a {1}.",
	"crux.users.selected.plural" : "{0} utilizadores selecionados.",
	"crux.user.selected.singular" :"{0} utilizador selecionado.",
	"crux.criteria.empty.secondaryfield.module" : "Não foi encontrado nenhum campo {0} correspondente no módulo {1}",
	"crux.criteria.empty.secondaryfield" : "Não existem nenhum outro campo {0} para comparação, por favor introduza um valor para comparar.",
	"crux.logged.in.role.definition" : "A função de utilizador que inicia as ações de registo",
	"zia.similarity.smartfilter.score":"Pontua\u00e7\u00e3o de similaridade",//no i18n
	"zia.similarity.smartfilter.records":"{0} semelhante de",//no i18n
	"zia.similarity.smartfilter.records.search":"Mostrar {0} semelhante de",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"N\u00e3o pode exportar mais de {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} deve ser superior ou igual a {1}.",
	"crux.users.selected.plural" : "{0} utilizadores selecionados.",
	"crux.user.selected.singular" :"{0} utilizador selecionado.",
	"crux.criteria.empty.secondaryfield.module" : "Não foi encontrado nenhum campo {0} correspondente no módulo {1}",
	"crux.criteria.empty.secondaryfield" : "Não existem nenhum outro campo {0} para comparação, por favor introduza um valor para comparar.",
	"crux.logged.in.role.definition" : "A função de utilizador que inicia as ações de registo",
	"crux.max.limit.unselect" : "Não é possível anular a seleção de mais de {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d já foi selecionado" //NO I18N

}
